/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const tempDataSlice = createSlice({
  name: 'tempDataSlice',
  initialState: {
    user: '',
    game: '',
  },
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload;
      return state;
    },
    setGame: (state, { payload }) => {
      state.game = payload;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser, setGame } = tempDataSlice.actions;

export default tempDataSlice.reducer;
