/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const uiSlice = createSlice({
    name: 'uiSlice',
    initialState: {
        navBarOpen: true,
        message: {},
        isLoginChecked: false,
        isLoggedIn: false,
        nextPath: '',
        startLogin: '',
    },
    reducers: {
        toggleNavBar: (state) => {
            state.navBarOpen = !state.navBarOpen;
            return state;
        },
        sendMessage: (state, { payload }) => {
            state.message = payload;
            return state;
        },
        setLoginStatus: (state, { payload }) => {
            state.isLoggedIn = payload;
            state.isLoginChecked = true;
            return state;
        },
        readyForLogin: (state) => {
            state.isLoginChecked = true;
            return state;
        },
        redirectTo: (state, { payload }) => {
            state.nextPath = payload;
            return state;
        },
        startLogin: (state, { payload }) => {
            state.startLogin = payload;
            return state;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
 toggleNavBar, sendMessage, setLoginStatus, readyForLogin, redirectTo, startLogin,
} = uiSlice.actions;

export default uiSlice.reducer;
