/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-globals */
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {
 getAuth, signInWithPopup, GoogleAuthProvider, signOut,
} from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAK2Px70jU12o9eVsiMglexKoHYmHToDQ4',
  authDomain: 'damary-gga.firebaseapp.com',
  projectId: 'damary-gga',
  storageBucket: 'damary-gga.appspot.com',
  messagingSenderId: '307920630463',
  appId: '1:307920630463:web:115a4df4f30722d4208c0a',
};

// Initialize Firebase
export default class FirebaseApp {
    // eslint-disable-next-line no-use-before-define
    static inst;

    _googleProvider;

    _auth;

    constructor() {
        if (FirebaseApp.inst) {
            return;
        }
        initializeApp(firebaseConfig);
        FirebaseApp.inst = this;
        this._googleProvider = new GoogleAuthProvider();
        this._auth = getAuth();
    }

    get auth() {
        return this._auth;
    }

    get googleProvider() {
        return this._googleProvider;
    }

    checkTokenValid() {
        if (
            this._auth?.currentUser
            && Number(this._auth?.currentUser?.metadata?.lastLoginAt || 0) + 86400000 * 5 > Date.now()
            && this._auth?.currentUser.email.endsWith('@damary.net')
        ) {
            return true;
        }

        return false;
    }

    get isLoggedIn() {
        return this.checkTokenValid();
    }

    logout() {
        signOut(this._auth);
    }
}
