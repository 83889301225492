/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { Select, MenuItem } from '@mui/material';
import Symbol from '../Symbol';

function EmptyE() {
  return <div className="p-0" />;
}

export default function SymbolMatrix({
    matrix = '',
    gameImg,
    reelLength,
    onMatrixSymbolChanged = (index, sym) => {},
    symbols = '',
}) {
    let tempMax = matrix.split(',');
    const maxt = [];
    let x = 0;
    let y = 0;
    while (tempMax.length) {
        if (!maxt[x]) {
            maxt[x] = [];
        }
        maxt[x][y] = tempMax.shift();
        y++;
        if (y >= reelLength) {
            y = 0;
            x++;
        }
    }
    tempMax = maxt;

    //   tempMax = maxt[0].map((_, colIndex) => maxt.map((row) => row[colIndex]));
    return (
        <div>
            <Box sx={{ flexGrow: 1, p: 1 }} className="w-full">
                <Grid
                    container
                    // spacing={1}
                    sx={
                        {
                            // '--Grid-borderWidth': '1px',
                            // borderTop: 'var(--Grid-borderWidth) solid',
                            // borderLeft: 'var(--Grid-borderWidth) solid',
                            // borderColor: 'divider',
                            // '& > div': {
                            //     borderRight: 'var(--Grid-borderWidth) solid',
                            //     borderBottom: 'var(--Grid-borderWidth) solid',
                            //     borderColor: 'divider',
                            // },
                        }
                    }
                >
                    <div className="flex flex-row">
                        {tempMax.map((reel, reelIndex) => (
                            <div className="flex flex-col" key={`${reelIndex}${reel.join('')}`}>
                                {reel.map((val, index) => (
                                    <Select
                                        value={val}
                                        onChange={(event) =>
                                            onMatrixSymbolChanged(
                                                reel.length * reelIndex + index,
                                                event.target.value,
                                            )}
                                        IconComponent={EmptyE}
                                        sx={{ padding: 0 }}
                                        className="matrix-select flex-1 rounded-none divide-gray-200"
                                        key={index + val}
                                    >
                                        {(symbols || '').split(',').map((sym) => (
                                            <MenuItem
                                                value={sym}
                                                key={sym}
                                                sx={{ maxWidth: 50, padding: 0 }}
                                            >
                                                <Symbol
                                                    key={`${reelIndex}${index}${sym}`}
                                                    sym={sym}
                                                    gameImg={gameImg}
                                                />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                ))}
                            </div>
                        ))}
                    </div>

                    {/* {tempMax.map((reel, reelIndex) =>
                      reel.map((val, index) => (
                          <Grid key={index} xs={12 / reel.length} className="p-0">
                              <img
                                  src={`${process.env.PUBLIC_URL}/images/symbols/${
                                      (isFreegame ? freegameImg : gameImg) || gameImg
                                  }/symbol_${val}.png`}
                                  alt={`symbol_${val}`}
                                  onClick={() => {
                                    onMatrixSymbolSelect(reel.length, reelLength, index, reelIndex);
                                  }}
                              />
                          </Grid>
                      )))} */}
                </Grid>
            </Box>
        </div>
    );
}
