/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
 TextField, Button, FormControlLabel, Paper, FormControl, FormLabel, RadioGroup, Radio,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import SendIcon from '@mui/icons-material/Send';
import ShuffleOnIcon from '@mui/icons-material/ShuffleOn';
import SymbolMatrix from './Matrix';
import Dropdown from '../Dropdown';

function MatrixInput({
 selectedGame, onSubmit, matrixOption, id, buildGameCustom, gameModes = [], customButtons,
}) {
    const [matrix, setMatrix] = useState(selectedGame?.matrix.default);
    const [gameMode, setGameMode] = useState(0);
    const [isReadyMatrix, setReadyMatrix] = useState(false);

    useEffect(() => {
        setReadyMatrix(false);
        setMatrix(selectedGame?.matrix.default);
        setGameMode(0);
        setTimeout(() => setReadyMatrix(true), 500);
    }, [selectedGame]);

    const sendCheatMatrix = () => {
        onSubmit(matrix, gameModes[gameMode]);
    };
    const onMatrixChanged = (event) => {
        setMatrix(event.target.value);
    };

    const randomMatrix = () => {
        const pool = (gameModes[gameMode]?.symbols || '').split(',');
        const newMax = matrix.split(',').map(() => pool[Math.floor(Math.random() * pool.length)]).join(',');
        setMatrix(newMax);
    };

    return (
        <Grid container xs={12}>
            <Grid item xs={12}>
                <Paper square elevation={3} className="p-1">
                    <Grid container xs={12}>
                        <Grid container xs={8} className="mt-1">
                            <div className="flex flex-col w-full">
                                <div className="flex h-14 w-full mb-5">
                                    <TextField
                                        className="w-full grow "
                                        id={`matrix-input${id}`}
                                        label="Matrix"
                                        value={matrix}
                                        onChange={onMatrixChanged}
                                        autoComplete={`matrix_${selectedGame?.value}`}
                                    />
                                    <Button
                                        type="gen"
                                        className="flex-none bg-indigo-500 shadow-lg shadow-indigo-500/50  h-14"
                                        variant="contained"
                                        onClick={randomMatrix}
                                    >
                                        <ShuffleOnIcon />
                                    </Button>
                                    <Button
                                        className="flex-none bg-indigo-500 shadow-lg shadow-indigo-500/50 transition duration-150 hover:-rotate-90"
                                        variant="contained"
                                        onClick={sendCheatMatrix}
                                    >
                                        <SendIcon />
                                    </Button>
                                </div>

                                <div className="flex flex-grow">
                                    <div className="flex w-full">
                                        <Dropdown
                                            label="Quick matrix"
                                            options={matrixOption}
                                            className="grow mx-3"
                                            // defaultValue={matrixOption[0]}
                                            onChange={(val) => {
                                                const option = matrixOption.find(
                                                    (g) => g.value === val.target.value,
                                                );
                                                option?.matrix && setMatrix(option.matrix);
                                            }}
                                        />
                                        {customButtons(gameModes[gameMode], (i) => {
                                            gameModes[gameMode].jackpotType = i;
                                            gameModes[gameMode].level = 5;
                                        })}
                                    </div>
                                    <div className="flex min-w-fit">
                                        <FormControl className="">
                                            <FormLabel id="game-mode">Game mode</FormLabel>
                                            <RadioGroup
                                                aria-labelledby="game-mode"
                                                name="controlled-radio-buttons-group"
                                                value={gameMode}
                                                onChange={(e) => {
                                                    setGameMode(e.target.value);
                                                    if (gameModes[e.target.value]?.defaultMatrix) {
                                                        setMatrix(
                                                            gameModes[e.target.value]?.defaultMatrix,
                                                        );
                                                    }
                                                    setReadyMatrix(false);
                                                    setTimeout(() => setReadyMatrix(true), 500);
                                                }}
                                            >
                                                {gameModes.map((i, ind) => (
                                                    <FormControlLabel
                                                        className="h-7"
                                                        value={ind}
                                                        control={<Radio />}
                                                        label={i?.name}
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        key={(i?.name || '') + ind}
                                                    />
                                                ))}
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={4} className="p-0 m-0">
                            {!isReadyMatrix ? (
                                ''
                            ) : (
                                // eslint-disable-next-line react/jsx-no-useless-fragment
                                <>
                                    {gameModes[gameMode]?.custom ? (
                                        buildGameCustom(gameModes[gameMode])
                                    ) : (
                                        <SymbolMatrix
                                            matrix={matrix}
                                            reelLength={Number(
                                                gameModes[gameMode]?.tableFormat?.[0]
                                                    || selectedGame?.tableFormat?.[0]
                                                    || 4,
                                            )}
                                            gameImg={gameModes[gameMode]?.image}
                                            onMatrixSymbolChanged={(ind, sym) => {
                                                const newMatrix = matrix.split(',');
                                                newMatrix[ind] = sym;
                                                setMatrix(newMatrix.join(','));
                                            }}
                                            symbols={gameModes[gameMode]?.symbols}
                                        />
                                    )}
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default MatrixInput;
