import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { StyledEngineProvider } from '@mui/material/styles';

import { ThemeProvider } from '@emotion/react';
import store from './Redux/store';

import reportWebVitals from './reportWebVitals';

// import './../index.css'; // tailwin parcel
import Home from './Routes/Home';
import Admin from './Routes/Admin';
import ErrorPage from './Routes/Error';
import NavBar from './Components/NavBar';
import TokenManager from './Routes/TokenManager';
import CheatBoard from './Routes/CheatBoard';

import './tailwin.css';
import theme from './Theme/themes';
import BonusToken from './Routes/BonusToken';
import LoginPage from './Routes/Login';
import AuthApp from './Components/Auth/Auth';

const withNavBar = (child) => <AuthApp><NavBar>{child}</NavBar></AuthApp>;

const router = createBrowserRouter([
  {
    path: '/',
    element: withNavBar(<Home />),
    errorElement: withNavBar(<ErrorPage />),
  },
  {
    path: '/admin',
    element: withNavBar(<Admin />),
  },
  {
    path: '/token',
    element: withNavBar(<TokenManager />),
  },
  {
    path: '/cheat',
    element: withNavBar(<CheatBoard />),
  },
  {
    path: '/freecode',
    element: withNavBar(<BonusToken />),
  },
  {
    path: '/login',
    element: <AuthApp><LoginPage /></AuthApp>,
  },
]);

// eslint-disable-next-line no-undef
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
