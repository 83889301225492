import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Button, TextField, Tooltip } from '@mui/material';
import * as jose from 'jose';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import Dropdown from '../Components/Dropdown';
import { setUser } from '../Redux/temp';

// import Header from '../Components/Header/Header';
// import Categories from '../Components/Category';

// const jwt = require('jsonwebtoken');
const gameConfig = require('../Configs/gameMatrix.json');

const secret = new TextEncoder().encode('secret');

function BonusToken() {
    const cachedUser = useSelector((state) => state.cacheData?.user);
    const dispatch = useDispatch();
    const [userId, setUserId] = useState(cachedUser);
    const [betId, setBetId] = useState('10');
    const [freeSpin, setFreeSpin] = useState('5');
    const [token, setToken] = useState('');
    const [selectedGame, setGame] = useState(gameConfig[0]);
    const [isCopied, setCopied] = useState(false);
    const [refresh, setRefresh] = useState('');

    useEffect(() => {
        if (isCopied) {
            setTimeout(() => {
                setCopied(false);
            }, 500);
        }
    }, [isCopied]);

    const gen = async () => {
        const code = uuidv4();

        const jwt = await new jose.SignJWT({
            exp: 1700000000,
            code,
            name: code,
            gameId: `kts_${selectedGame.serviceId}`,
            betId: Number(betId || 0),
            spinFree: Number(freeSpin || 0),
            type: 'anyUser',
            userId,
        })
            .setProtectedHeader({ alg: 'HS256', typ: 'JWT' })
            .setIssuedAt()
            .sign(secret);

        setToken(jwt);
    };

    useEffect(() => {
        gen();
    }, [selectedGame, userId, betId, freeSpin, refresh]);

    return (
        <Grid container spacing={4} sm={12} xl={8}>
            <Grid item xs={8}>
                <TextField
                    className="w-full"
                    id="user-input"
                    label="User"
                    defaultValue={userId}
                    onBlur={(e) => {
                        setUserId(e.target.value);
                        dispatch(setUser(e.target.value));
                    }}
                    autoComplete="userID"
                />
            </Grid>
            <Grid container xs={12}>
                <Grid item xs={8}>
                    <Dropdown
                        label="Select Game"
                        className="w-full"
                        options={gameConfig}
                        value={selectedGame?.value}
                        onChange={(val) => {
                            const game = gameConfig.find((g) => g.value === val.target.value);
                            setGame(game);
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container xs={12}>
                <Grid item xs={3}>
                    <TextField
                        className="w-full"
                        id="bet-input"
                        label="Bet Id"
                        value={betId}
                        onChange={(e) => setBetId(e.target.value)}
                        autoComplete="betID"
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        className="w-full"
                        id="freeSpin-input"
                        label="Free spin"
                        value={freeSpin}
                        onChange={(e) => setFreeSpin(e.target.value)}
                        autoComplete="freeSpin"
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        type="gen"
                        className="flex-none bg-indigo-500 shadow-lg shadow-indigo-500/50  h-14"
                        variant="contained"
                        onClick={() => { setRefresh(uuidv4()); }}
                    >
                        <AutorenewIcon />
                    </Button>
                </Grid>
            </Grid>
            <Grid container xs={12}>
                <Grid item xs={8}>
                    <Tooltip title={isCopied ? 'Copied!' : 'Click to copy!'} disableInteractive>
                        <TextField
                            className="w-full cursor-pointer"
                            id="user-token"
                            label="Token"
                            value={token}
                            multiline
                            maxRows={10}
                            disabled
                            onClick={() => {
                                setCopied(!isCopied);
                                // eslint-disable-next-line no-undef
                                navigator.clipboard.writeText(token);
                            }}
                        />
                    </Tooltip>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default BonusToken;
