import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useSelector, useDispatch } from 'react-redux';
import { sendMessage } from '../Redux/uiSlider';

const Alert = React.forwardRef((
  props,
  ref,
) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

export default function Snackbars() {
  const [open, setOpen] = React.useState(false);
  const message = useSelector((state) => state.ui.message);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (message?.text) {
      setOpen(true);
    }
  }, [message]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(sendMessage({}));
    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={message?.type ?? 'success'} sx={{ width: '100%' }}>
        {message?.text}
      </Alert>
    </Snackbar>
  );
}
