/* eslint-disable no-undef */
/* eslint-disable no-alert */
/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-console */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable global-require */
import {
 TextField, IconButton, InputBase, Paper, Button, RadioGroup, FormControlLabel, Radio, FormControl, FormLabel,
} from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { deepPurple } from '@mui/material/colors';
import SendIcon from '@mui/icons-material/Send';
import Dropdown from '../Components/Dropdown';
import api from '../Utilities/api';
import { sendMessage } from '../Redux/uiSlider';
import MatrixInput from '../Components/CheatMatrix/MatrixInput';
import { setUser } from '../Redux/temp';
import Symbol from '../Components/Symbol';

// import Header from '../Components/Header/Header';
// import Categories from '../Components/Category';

const gameConfig = require('../Configs/gameMatrix.json');

export default function CheatBoard() {
    const cachedUser = useSelector((state) => state.cacheData?.user);
    // const cachedGame = useSelector((state) => state.cacheData?.game);
    const [userId, setUserId] = useState(cachedUser || '');
    const [selectedGame, setGame] = useState(gameConfig[0]);
    const [numMulMax, setNumMulMax] = useState(3);

    const dispatch = useDispatch();

    const matrixOption = (() =>
        Object.keys(selectedGame?.matrix || {}).map((key) => ({
            matrix: selectedGame.matrix[key],
            name: key,
            value: key,
        })))();

    useEffect(() => {}, [selectedGame]);

    const onSendMatrixCustomMode = (sym, gameMode) => {
        if (userId.trim() === '') {
            alert('Missing UserId!');
            return;
        }
        switch (selectedGame.serviceId) {
            case '9911':
                api.sendCheatMatrix({
                    userId: userId.trim(),
                    stackedTypeMode: 1,
                    matrixData:
                        '3,7,8,2,6,7,4,5,4,7,2,2,2,K,K,4,7,2,3,2,9,6,4,4,8,5,8,4,7,5,9,6,4,2,7,8,5,5,3,2,5,6,5,6,8,9,8,7',
                    serviceId: selectedGame.serviceId,
                    jackpotType: selectedGame.jackpotType,
                    tableFormat: selectedGame.tableFormat,
                    megaSymbolCode: sym,
                });
                break;

            case '9991':
                api.sendCheatMatrix({
                    userId: userId.trim(),
                    stackedTypeMode: gameMode.mode || 1,
                    matrixData: selectedGame.matrix.default,
                    serviceId: selectedGame.serviceId,
                    jackpotType: sym,
                    stackedReel5: gameMode.level || 1, // 1-5 bet level
                    stackedReel1: 1, // 1-5 bet level
                    stackedCodeReel1: '', // Be _GRAND if force win JP
                });
                break;
            default:
                break;
        }
    };

    const buildGameCustom = (gameMode) => {
        switch (selectedGame.serviceId) {
            case '9911':
                return (
                    <div className="flex w-full flex-wrap">
                        {gameMode.symbols.split(',').map((i) => (
                            <Button
                                type="gen"
                                className="m-2 flex justify-between h-16 w-32"
                                variant="contained"
                                onClick={() => {
                                    onSendMatrixCustomMode(i);
                                }}
                                key={`${i}`}
                            >
                                <Symbol
                                    sym={i}
                                    gameImg={gameMode.image || selectedGame.image}
                                    className="h-14"
                                />
                                <SendIcon />
                            </Button>
                        ))}
                    </div>
                );
            case '9991':
                return (
                    <div className="flex w-full flex-wrap">
                        {gameMode.symbols.map((i) => (
                            <Button
                                type="gen"
                                className="m-2 flex justify-between h-16 w-32"
                                variant="contained"
                                onClick={() => {
                                    onSendMatrixCustomMode(i, gameMode);
                                }}
                                key={`${i}`}
                            >
                                <Symbol
                                    sym={i}
                                    gameImg={gameMode.image || selectedGame.image}
                                    className="h-14"
                                />
                                <SendIcon />
                            </Button>
                        ))}
                    </div>
                );
            default:
                return '';
        }
    };

    const onSendMatrix = (max, gameMode) => {
        let matrixData = max;
        if (userId.trim() === '') {
            alert('Missing UserId!');
            return;
        }
        const payload = {
            userId: userId.trim(),
            stackedTypeMode: gameMode.mode || 1,
            matrixData,
            serviceId: selectedGame.serviceId,
            jackpotType: selectedGame.jackpotType,
            tableFormat: selectedGame.tableFormat,
            stackedReel5: gameMode.level || 1,
            stackedCodeReel1: '', // Be _GRAND if force win JP
        };

        // Custom payload
        if (selectedGame.serviceId === '9911') {
            const stackedCodeReel1 = [];
            const stackedCodeReel5 = [];
            matrixData = matrixData
                .split(',')
                .map((sym, ind) => {
                    if (sym === 'K1' || sym === 'K2' || sym === 'K') {
                        stackedCodeReel1.push(sym);
                        stackedCodeReel5.push(ind);
                        return 'K';
                    }

                    return sym;
                })
                .join(',');

            payload.stackedCodeReel1 = stackedCodeReel1.join(',');
            payload.stackedCodeReel5 = stackedCodeReel5.join(',');
            payload.stackedTypeMode = 1;
            payload.megaSymbolCode = '';
            payload.matrixData = matrixData;
            api.sendCheatMatrix(payload);
        } else if (selectedGame.serviceId === '9991') {
            if (gameMode.jackpotType && gameMode.jackpotType !== 'NO') {
                payload.jackpotType = gameMode.jackpotType;
                payload.stackedReel1 = 1; // Force win Bonus
                if (gameMode.jackpotType !== 'RANDOM') {
                    payload.stackedCodeReel1 = '_GRAND'; // Force win JP
                }
            }
            api.sendCheatMatrix(payload);
        } else {
            api.sendCheatMatrix(payload);
        }

        dispatch(sendMessage({ text: 'Cheat matrix SENT!', type: 'success' }));
    };

    const customButtons = (gameMode, onChanged) => {
        switch (selectedGame.serviceId) {
            case '9911':
                return '';

            case '9991':
                return (
                    // <Dropdown
                    //     label="Bonus Game Jackpot"
                    //     options={}
                    //     className="grow mx-3"
                    //     defaultValue="NO"
                    //     getLabel={(i) => `${i}`}
                    //     getValue={(i) => i}
                    //     onChange={(val) => {
                    //         onChanged && onChanged(val.target.value);
                    //     }}
                    // />
                    <FormControl className="">
                        <FormLabel id="game-mode">Bonus Game Win</FormLabel>
                        <RadioGroup
                            defaultValue="NO"
                            onChange={(e) => {
                                onChanged && onChanged(e.target.value);
                            }}
                        >
                            {['NO', 'RANDOM', '_MINI', '_MINOR', '_MAJOR', '_GRAND'].map(
                                (i, ind) => (
                                    <FormControlLabel
                                        className="h-7"
                                        value={i}
                                        control={<Radio />}
                                        label={i}
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={i}
                                    />
                                ),
                            )}
                        </RadioGroup>
                    </FormControl>
                );
            default:
                return '';
        }
    };
    return (
        <Paper
            sx={{
                height: 'calc(100vh - 60px)',
                '&::-webkit-scrollbar': {
                    width: 10,
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: deepPurple[100],
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#8860FF',
                    borderRadius: 2,
                },
            }}
            className="m-1 pt-3 overflow-x-hidden min-h-fit"
            elevation={0}
        >
            <Grid container spacing={2} sm={12} xl={8}>
                <Grid item xs={8}>
                    <TextField
                        className="w-full"
                        id="user-input"
                        label="User"
                        defaultValue={userId}
                        onBlur={(e) => {
                            setUserId(e.target.value);
                            dispatch(setUser(e.target.value));
                        }}
                        autoComplete="userID"
                    />
                </Grid>
                <Grid container xs={12}>
                    <Grid item xs={8}>
                        <Dropdown
                            label="Select Game"
                            className="w-full"
                            options={gameConfig}
                            value={selectedGame?.value}
                            onChange={(val) => {
                                const game = gameConfig.find((g) => g.value === val.target.value);
                                setGame(game);
                            }}
                        />
                    </Grid>

                    <Grid item xs={3} className="flex">
                        <Paper
                            component="form"
                            sx={{
                                p: '2px 4px',
                                display: 'flex',
                                // alignItems: 'center',
                                // width: 400,
                                // justifyContent: 'space-between',
                            }}
                        >
                            <IconButton
                                sx={{ p: '10px' }}
                                aria-label="menu"
                                onClick={() => {
                                    setNumMulMax(numMulMax - 1 || 1);
                                }}
                            >
                                <RemoveIcon />
                            </IconButton>
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="0"
                                inputProps={{ 'aria-label': '0' }}
                                disabled
                                value={numMulMax}
                            />
                            <IconButton
                                type="button"
                                sx={{ p: '10px' }}
                                aria-label="search"
                                onClick={() => {
                                    setNumMulMax(numMulMax + 1);
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </Paper>
                    </Grid>
                </Grid>

                <>
                    {[...Array(numMulMax).keys()].map((i) => (
                        <MatrixInput
                            selectedGame={selectedGame}
                            onSubmit={onSendMatrix}
                            key={`max${i}`}
                            matrixOption={matrixOption}
                            customButtons={customButtons}
                            id={i}
                            buildGameCustom={buildGameCustom}
                            gameModes={selectedGame.gameModes}
                        />
                    ))}
                </>
            </Grid>
        </Paper>
    );
}
