/* eslint-disable max-len */
import { Box, Button } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { startLogin } from '../Redux/uiSlider';

// import Header from '../Components/Header/Header';
// import Categories from '../Components/Category';

function LoginPage() {
    const dispatch = useDispatch();
    return (
        <div className="flex justify-center">
        <div className="flex flex-col max-w-screen-md self-center">
            <img alt="troll-warning" src={`${process.env.PUBLIC_URL}/images/bg/bg2.jpg`} />
            <Button className="animate-bounce text-black" disabled>
                Click to login
            </Button>
            <Button
                className="m-10 bg-gray-100 shadow-lg shadow-gray-500/50 transition duration-150 hover:bg-orange-500 text-black"
                variant="contained"
                type="gen"
                onClick={() => {
                    dispatch(startLogin(true));
                }}
            >
                Login with Google
            </Button>
        </div>
        </div>
    );
}

export default LoginPage;
