import React from 'react';
import Dashboard from '../Components/Dashboard/Dashboard';
// import Header from '../Components/Header/Header';
// import Categories from '../Components/Category';

function Home() {
  return (
      <div className="App">
          <Dashboard />
          {/* <Button
              onClick={() => {
                  FirebaseApp.inst.logout();
              }}
          >
              Logout
          </Button> */}
      </div>
  );
}

export default Home;
