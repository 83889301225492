import React, { useEffect } from 'react';
import './loading.css';

function PageLoading() {
    return (
        <div className="w-full h-screen">
            <div className="centered">
                <div className="blob-1" />
                <div className="blob-2" />
            </div>
        </div>
    );
}

export default PageLoading;
