/* eslint-disable max-len */
import axios from 'axios';

const API = 'https://api.damary.xyz';

let axiosIns = null;

const getInstance = () => {
    if (axiosIns) return axiosIns;
    axiosIns = axios.create({
        baseURL: API,
        timeout: 10000,
    });
    return axiosIns;
};

const getUserTokens = async () => {
    const response = await getInstance().get('/manage-token-devex/getListData');

    if (response.status === 200) {
        return response.data.data;
    }
    return [];
};

const genToken = async (user) => {
    const response = await getInstance().get(
        `/manage-token-devex/generateNewToken?userName=${user.UserID}&password=${user.Password}`,
    );
    if (response.status === 200) {
        return response.data.data;
    }
    return [];
};

const sendCheatMatrix = async (data) => {
    const payload = Object.keys(data).map((i) => `${i}=${data[i]}`).join('&');

    const response = await getInstance().post(
        `/${data.serviceId}/inputed?${payload}`,
    );
    return response;
};

export default {
    getUserTokens,
    genToken,
    sendCheatMatrix,
};
