import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function Dropdown({
  value,
  onChange,
  className,
  options = [],
  label = 'Label',
  id,
  getValue = (option) => option.value || option,
  getLabel = (option) => option.name || option.value || option,
  defaultValue,
}) {
    const props = {
        defaultValue,
    };
    if (value) {
        props.defaultValue = value;
    }
  return (
      <FormControl variant="standard" className={className}>
          <InputLabel id={id}>{label}</InputLabel>
          <Select labelId={id} id={id} onChange={onChange} label={label} {...props}>
              {options.map((option) => (
                  <MenuItem value={getValue(option)} key={getValue(option)}>
                      {getLabel(option)}
                  </MenuItem>
              ))}
          </Select>
      </FormControl>
  );
}
