/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import {
 styled, IconButton, Typography, Paper, Link, Tooltip,
} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { useSelector, useDispatch } from 'react-redux';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MuiAppBar from '@mui/material/AppBar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import { useNavigate, useLocation } from 'react-router-dom';

// import { deepPurple } from '@mui/material/colors';
import { toggleNavBar } from '../Redux/uiSlider';
import Snackbars from './Snackbar';
import FirebaseApp from '../Firebase';
import PageLoading from './Loading/PageLoading';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    backgroundColor: theme.palette.primary.main,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        top: '-100px',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            // whiteSpace: 'nowrap',
            width: drawerWidth,
            // height: '95%',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            background: 'transparent',
            // boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

const nav = [
    {
        title: '',
        child: [
            { ref: '/', name: 'Home', icon: <DashboardIcon /> },
        ],
    },
    {
        title: 'Game tools',
        child: [
            { ref: '/token', name: 'Token Manager', icon: <PeopleIcon /> },
            { ref: '/cheat', name: 'Cheat Board', icon: <BarChartIcon /> },
            { ref: '/freecode', name: 'Free Spin Code', icon: <BarChartIcon /> },
        ],
    },
];
// color="fcba03"
export default function NavBar({ children }) {
    const open = useSelector((state) => state.ui.navBarOpen);
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <Box
            className="bg-amber-100 p-5 box-border bg-gradient-to-r from-slate-300 to-emerald-50"
            // sx={{ backgroundImage: `url('${process.env.PUBLIC_URL}/images/bg/bg.png')` }}
        >
            <Box component="div" className="flex divide-x-0">
                <Snackbars />
                <CssBaseline />
                {/* <AppBar position="absolute" open={open} className="bg-transparent">
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => {
                                dispatch(toggleNavBar());
                            }}
                            sx={{
                                marginRight: '36px',
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="secondary"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            God of Dogs
                        </Typography>
                        <IconButton color="inherit">
                            <Badge badgeContent={0} color="secondary">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                    </Toolbar>
                </AppBar> */}
                <Drawer
                    variant="permanent"
                    open={open}
                    hideBackdrop
                    elevation={0}
                    className="rounded-l-3xl"
                >
                    <Paper className="bg-amber-100 rounded-l-3xl h-full pl-5" elevation={0} square>
                        <Toolbar
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                px: [1],
                            }}
                        >
                            {/* <Header /> */}
                            <IconButton
                                onClick={() => {
                                    dispatch(toggleNavBar());
                                }}
                            >
                                {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                            </IconButton>
                        </Toolbar>
                        <List component="nav" className="m-0 p-0">
                            {nav.map((category, ind) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <div key={category.title + ind}>
                                    {category.title && (
                                        <ListSubheader
                                            component="div"
                                            inset
                                            className="bg-transparent"
                                        >
                                            {category.title}
                                        </ListSubheader>
                                    )}
                                    {category.child?.map((child) => (
                                        <Tooltip
                                            title={child.name}
                                            key={child.name}
                                            disableInteractive
                                        >
                                            <ListItemButton
                                                onClick={() => {
                                                    navigate(child.ref);
                                                }}
                                                key={child.name}
                                                className={`rounded-r-0 border-0 ${
                                                    child.ref === location.pathname
                                                        ? 'rounded-l-full bg-white'
                                                        : ''
                                                }`}
                                            >
                                                <ListItemIcon>{child.icon}</ListItemIcon>
                                                <ListItemText primary={child.name} />
                                            </ListItemButton>
                                        </Tooltip>
                                    ))}
                                    <Divider sx={{ my: 1 }} />
                                </div>
                            ))}
                        </List>
                    </Paper>
                </Drawer>
                <Box
                    component="div"
                    sx={{
                        flexGrow: 1,
                        // height: 'calc(100vh - 40px)',
                        // overflow: 'auto',
                    }}
                    className="overflow-hidden"
                >
                    {/* <div className="mx-2 mt-2"> */}
                    <Paper
                        elevation={0}
                        square
                        className="rounded-r-3xl"
                        sx={{
                            // flexGrow: 1,
                            height: 'calc(100vh - 40px)',
                            // overflow: 'auto',
                        }}
                    >
                        <div className="p-3">{children}</div>
                    </Paper>
                </Box>
            </Box>
        </Box>
    );
}
