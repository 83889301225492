import React from 'react';

function Admin() {
  return (
    <div className="App">
      <header className="App-header">
        Admin
      </header>
    </div>
  );
}

export default Admin;
