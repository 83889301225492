import { configureStore } from '@reduxjs/toolkit';
import tempDataSlider from './temp';
import uiSlider from './uiSlider';

export default configureStore({
  reducer: {
    ui: uiSlider,
    cacheData: tempDataSlider,
  },
});
