import React from 'react';
import './loading.css';

function Loading() {
  return (
      <div className="w-full h-screen">
          <div className="centered">
              <div className="blob-1" />
              <div className="blob-2" />
          </div>
      </div>
  );
}

export default Loading;
