import { Tooltip } from '@mui/material';
import React from 'react';

function Symbol({
    sym,
    gameImg,
    className,
}) {
  return (
      <Tooltip title={`${sym}`} arrow disableInteractive placement="right">
        <div>
          <img
              src={`${process.env.PUBLIC_URL}/images/symbols/${gameImg}/symbol_${sym}.png`}
              alt={`symbol_${sym}`}
              className={className}
          />
          <div
            className="
                absolute
                bottom-0 left-0
                text-rose-700
                bg-lime-200
                rounded
                opacity-80
                hover:animate-ping
                px-1
                symbol-text
            "
          >
                {sym}
          </div>
        </div>
      </Tooltip>
  );
}

export default Symbol;
