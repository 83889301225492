/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import { useNavigate, useLocation } from 'react-router-dom';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { Button } from '@mui/material';
import FirebaseApp from '../../Firebase';
import {
 readyForLogin, redirectTo, setLoginStatus, startLogin,
} from '../../Redux/uiSlider';
import PageLoading from '../Loading/PageLoading';

export default function AuthApp({ children }) {
    const [isLoading, setIsLoading] = useState(true);
    const isReady = useSelector((state) => state.ui.isLoginChecked);
    const isLoggedIn = useSelector((state) => state.ui.isLoggedIn);
    const goLogin = useSelector((state) => state.ui.startLogin);
    const nextPath = useSelector((state) => state.ui.nextPath);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const login = () =>
        signInWithPopup(FirebaseApp.inst.auth, FirebaseApp.inst.googleProvider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                if (result.user.email.endsWith('@damary.net')) {
                    GoogleAuthProvider.credentialFromResult(result);
                    navigate('/');
                } else {
                    // eslint-disable-next-line no-alert, no-undef
                    alert('Email is not accessible');
                }
            })
            .catch((error) => {});

    useEffect(() => {
        if (nextPath) {
            dispatch(redirectTo(''));
            navigate(nextPath);
        }
    }, [nextPath]);
    useEffect(() => {
        if (goLogin) {
            dispatch(startLogin(false));
            login();
        }
    }, [goLogin]);

    useEffect(() => {
        if (!isReady) {
            // eslint-disable-next-line no-new
            new FirebaseApp();
            dispatch(readyForLogin());
        } else if (!isLoggedIn) {
            setTimeout(() => {
                setIsLoading(false);
                if (FirebaseApp.inst.isLoggedIn) {
                    dispatch(setLoginStatus(true));
                    navigate('/');
                } else {
                    navigate('/login');
                }
            }, 1000);
        }
    }, [isLoggedIn, isReady]);

    useEffect(() => {}, [isLoggedIn, isReady]);
    if (isLoading) {
        return <PageLoading />;
    }
    return (
        <div>
            {children}
            {/* <Button className="absolute top-0 left-0" onClick={() => FirebaseApp.inst.logout()}>Logout</Button> */}
        </div>
    );
}
