import {
  Paper, Grid, Button, styled,
  Tooltip,
  TextField,
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import { deepPurple } from '@mui/material/colors';
import Title from '../Components/Title';
import api from '../Utilities/api';
import Loading from '../Components/Loading/Loading';
// import Header from '../Components/Header/Header';
// import Categories from '../Components/Category';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  margin: 0,
  cursor: 'pointer',
}));

const createCell = (child, isCopied = false, clickCb = () => {}) => (
  // eslint-disable-next-line max-len

  <Tooltip title={isCopied ? 'Copied!' : 'Click to copy!'}>
    <Item
    // variant="contained"
      elevation={0}
      square
      onClick={() => {
        clickCb && clickCb();
        // eslint-disable-next-line no-undef
        navigator.clipboard.writeText(child);
      }}
      className="text-left"
    >
      {child}
    </Item>
  </Tooltip>
);

export default function TokenManager() {
  const [isCopied, setCopied] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => { setCopied(false); }, 500);
    }
  }, [isCopied]);

  useEffect(() => {
    if (data.length === 0) {
      const call = async () => {
        const result = await api.getUserTokens();
        setData(result);
        setFilterData(result);
      };
      call();
    }
  }, [data]);

  const filterUserList = (text, list = data) => list.filter((obj) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const field in obj) {
      if (((`${obj[field]}`) || '').toLocaleLowerCase().includes(text.toLocaleLowerCase())) {
        return true;
      }
    }
    return false;
  });
  const genToken = async (user) => {
    const result = await api.genToken(user);
    setData(result);
    setFilterData(filterUserList(searchText, result));
  };

  const onSearchChanged = (event) => {
    let value = event.target.value || '';
    setSearchText(value);
    value = value.trim();
    if (value !== '') {
      setFilterData(filterUserList(value));
    } else {
      setFilterData(data);
    }
  };

  if (!data || !data.length) {
    return (
        <Loading />
    );
  }

  return (
    <div className="Tken m-2">
      <Grid item xs={12}>
          <Title className="flex justify-between">
            Tokens
            <TextField
              id="standard-search"
              label="Search"
              type="search"
              variant="standard"
              className="w-2/4"
              value={searchText}
              onChange={onSearchChanged}
            />
          </Title>
          {/* <Paper sx={{ width: '100%', overflow: 'hidden' }} className="" square elevation={0}> */}
          <TableContainer sx={{
            maxHeight: 'calc(100vh - 150px)',
                '&::-webkit-scrollbar': {
                  width: 10,
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: deepPurple[100],
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#8860FF',
                  borderRadius: 2,
                },
        }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className="text-center text-xl">User</TableCell>
                <TableCell className="text-center text-xl">Token</TableCell>
                <TableCell className="text-center text-xl">Owner</TableCell>
                <TableCell className="text-center text-xl" align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {filterData.map((row) => (
                <TableRow key={row.UserID}>
                  <TableCell>
                    {createCell(row.UserID, isCopied, () => { setCopied(!isCopied); })}
                  </TableCell>
                  <TableCell>
                    {createCell(row.Token, isCopied, () => { setCopied(!isCopied); })}
                  </TableCell>
                  <TableCell>
                    {createCell(row.Owner, isCopied, () => { setCopied(!isCopied); })}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      type="gen"
                      onClick={() => {
                        genToken(row);
                      }}
                    >
                      Generate new Token
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          </TableContainer>
          {/* </Paper> */}
      </Grid>
    </div>
  );
}
